

































import Vue from 'vue';
import Component from 'vue-class-component';
import SubmissionActions from '@/components/submissions/SubmissionActions.vue';
import SubmissionDetail from '@/components/submissions/SubmissionDetail.vue';
import SubmissionData from '@/components/submissions/SubmissionData.vue';
import SurveyInfo from '@/components/surveys/SurveyInfo.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import Page from '@/components/ui/Page.vue';
import {surveyModule} from '@/store/modules';
import {Snackbar, SurveyResource} from '@/interfaces';
import {mdiContentCopy} from '@mdi/js';
import NotificationBar from '@/components/ui/NotificationBar.vue';

@Component({
  components: {
    NotificationBar,
    Page,
    PageTitle,
    SubmissionDetail,
    SurveyInfo,
    SubmissionActions,
    SubmissionData,
  },
})
export default class SurveyEmbedPreview extends Vue {
  private banner = true;
  private width = '100%';
  private height = '100%';
  private iconCopy = mdiContentCopy;
  private snackbar: Snackbar = {message: '', visible: false};

  get surveyEmbedCode(): string {
    if (!this.survey) {
      return '';
    }
    return `<iframe style="border:0;max-height:100%;" title="${
      this.survey.attributes.name
    }" width="${this.width}" height="${this.height}" src="${
      this.survey.attributes.url
    }?banner=${this.banner ? '1' : '0'}"></iframe>`;
  }

  get surveyId(): string {
    return this.$route.params.id;
  }
  get busy(): boolean {
    return surveyModule.busy;
  }
  get survey(): SurveyResource | undefined {
    return surveyModule.find(this.$route.params.id);
  }

  async created(): Promise<void> {
    if (!surveyModule.find(this.surveyId)) {
      await surveyModule.loadById(this.surveyId);
    }
  }

  copyCodeToClipboard(): void {
    this.snackbar.message = 'Embed code copied to clipboard';
    this.snackbar.visible = true;
  }
}
