
















































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {submissionModule} from '@/store/modules';
import {SubmissionResource} from '@/interfaces';
import {IResult, UAParser} from 'ua-parser-js';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
@Component({
  components: {CreatedModifiedCell},
})
export default class SubmissionDetail extends Vue {
  @Prop() private submission!: SubmissionResource;

  get busy(): boolean {
    return submissionModule.busy;
  }

  // get submission(): SubmissionResource | undefined {
  //   return submissionModule.find(this.submissionId);
  // }

  get parsedUserAgent(): IResult {
    const parser = new UAParser();
    parser.setUA(String(this.submission?.attributes.userAgent));
    return parser.getResult();
  }

  // async created(): Promise<void> {
  //   if (!submissionModule.find(this.submissionId)) {
  //     await submissionModule.loadById(this.submissionId);
  //   }
  // }
}
